.resize-warning {
  background: #face20;
  border-radius: 2px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: none;
  position: sticky;
  z-index: 100000;
  top: 0;
  padding-bottom: 5px;
}
.resize-warning-message {
  color: white;
  font-size: 14px;
  font-weight: 600;
}
@media only screen and (max-width: 480px) {
  body {
    overflow: auto;
  }
  .resize-warning {
    display: flex;
    position: fixed;
    z-index: 100000;
  }
  #root {
    margin-top: 20px;
  }
}
