.mai-form-group {
  display: flex;
  flex-direction: column;
  margin-left: 2%;
  padding-bottom: 1rem;
  &[disabled] {
    opacity: 0.7;
    .mai-form-control {
      background-color: #e9ecef !important;
      &:hover {
        border: 1px solid #dfe7f0;
      }
    }
  }
  margin: 0;
  width: 100%;
  // font-family: $font-family;
  // margin-right: 20px;
  .mai-input-group {
    display: flex;
    position: relative;
    .mai-form-control:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .mai-form-control:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .mai-input-group-prepend {
      .mai-input-group-text {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right-color: transparent;
      }
    }
    .mai-input-group-append {
      .mai-input-group-text {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left-color: transparent;
      }
    }
    .mai-input-group-text {
      display: flex;
      align-items: center;
      padding: 0.5rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: rgba(41, 47, 65, 0.5);
      text-align: center;
      white-space: nowrap;
      background-color: #ffffff;
      border: 1px solid #dfe7f0;
      border-radius: 4px;
      // min-width: 25px;
    }
  }

  input[type="text"].mai-form-control {
    padding: 0 0.7rem;
    &::placeholder {
      color: #bfbfbf;
    }
  }
  textarea.mai-form-control {
    padding: 0 0.7rem;
    &::placeholder {
      color: #bfbfbf;
    }
  }
  textarea.mai-form-control {
    padding: 0.4rem;
    min-height: 100px;
  }

  input:focus {
    border: 1px solid var(--primary_color);
    box-shadow: 0 12px 8px -10px rgba(58, 119, 235, 0.2);
  }
  &.text-left {
    text-align: left;
  }
  .info {
    position: absolute;
    bottom: -0.8rem;
  }
  .label {
    color: rgba(41, 47, 65, 0.5);
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.8px;
    padding-left: 0;
  }
}

.mai-form-control {
  min-height: 42px;
  // width: 100%;
  // min-width: 266px;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
  "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  "Helvetica Neue", sans-serif;

  box-sizing: border-box;
  border: 1px solid #dfe7f0;
  border-radius: 4px;
  background-color: #ffffff;
  color: #292f41;
  font-size: 18px;
  line-height: 24px;
  padding: 0 10px;
  outline: 0;
  transition: all 0.2s ease-out;
  // margin-bottom: 0.8rem;
  &:hover {
    border: 1px solid var(--primary_color);
  }
}

.mai-input-group-icon {
  position: absolute;
  right: calc(-100% + 34px);
  top: 8px;
  cursor: pointer;
  pointer-events: none;
}

.input-charcount {
  color: rgba(41, 47, 65, 0.5);
  font-size: 12px;
  letter-spacing: 0.8px;
  line-height: 12px;
  margin-top: 1%;
  margin-left: 2%;
}

@media screen and (max-width: 460px) {
  .mai-form-group {
    input.mai-form-control {
      // min-width: 98%;
    }
  }
}
