.admin-container {
  --transition: 0.2s all ease;
}
.dsp-admin-container {
  display: flex;
  height: 100%;
  min-height: 100vh;
  background: #f4f6fa;
  .admin-table-wrapper {
    flex: 1;
    margin-left: 14rem;
    margin-top: 88px;
    transition: var(--transition);
    &.active {
      margin-left: 5rem;
      //margin-top: 88px;
      transition: var(--transition);
    }
  }
}
.admin-header {
  // margin-bottom: 1rem;
  height: 88px;
  position: fixed;
  width: 100%;
  z-index: 50;
  background-color: #ffffff;
  box-shadow: 0 12px 8px -10px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 2rem;
  .title {
    color: #292f41;
    font-family: Source Sans Pro;
    font-size: 36px;
    font-weight: 600;
    line-height: 40px;
  }
  .admin-header-logo {
    width: 158px;
    margin-right: 1rem;
  }
  .go-back-container {
    margin-left: auto;
    margin-right: 2rem;
    display: flex;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
    p {
      color: var(--primary_color);
    }
    .int-icon {
      margin-left: 0.8rem;
      fill: var(--primary_color);
    }
  }
}

.admin-content {
  padding: 2rem 2rem;
  background-color: #f4f6fa;
  height: 100%; // TODO: Fix height
  min-height: 770px;
}
.admin-footer-wrapper {
  margin-left: 9rem;
}
@media only screen and (max-width: 450px) {
  .admin-header {
    height: 4rem;
    .title {
      font-size: 1.3rem;
    }
    .admin-header-logo {
      display: none;
    }
  }
  .dsp-admin-container {
    .admin-content {
      display: flex;
      flex-direction: column;
      padding: 1rem;

      .admin-content-inner {
        flex-direction: column !important;
        display: flex;
        position: relative;
        .admin-drawer-links {
          position: static;
          height: 100%;
          max-width: none;
          // width: 100%;
          padding: 0.8rem 0 0 1.5rem;
          margin-right: 0;
          .admin-drawer-links-title {
            padding: 0 0 0.5rem 0;
            font-size: 0.875rem;
          }
          ul {
            display: flex;
            flex-wrap: wrap;
            li {
              border: none;
              padding: 0.5rem 0;
              width: 8.75rem;
            }
            img {
              display: none;
            }
          }
        }
      }
      .admin-table-wrapper {
        margin: 0;
        .mai-tabs {
          .mai-tabs-container {
            .admin-moderation-tab {
              .filter-date-wrapper {
                width: auto;
              }
            }
          }
        }
        .accounts-moderation-table-wrapper {
          table {
            tr {
              white-space: nowrap;
              td {
                white-space: nowrap;
              }
            }
          }
        }
        .mai-filters {
          flex-direction: column;
          .mai-filters-field {
            display: flex;
          }
        }
      }
    }
  }
  .admin-footer-wrapper {
    margin: 0;
  }
}
