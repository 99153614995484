.input-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem 0.5rem;
  align-items: center;

  &.w-100 {
    margin: 1rem 0;
  }

  .label-wrapper {
    width: 30%;
    padding: 0 15px;
  }

  .input-wrapper {
    padding: 0 15px;
    width: 65%;

    &.w-100 {
      width: 100%;
      padding: 0;
    }
    .input-field {
      width: 100%;
      &.disabled {
        background-color: #e9ecef;
        & div {
          & input {
            background-color: #e9ecef;
          }
        }
      }

      input {
        padding: 8px 14px;
        font-size: 18px;
        line-height: 24px;
        color: #292f41;
      }
    }
  }
}
