.admin-search-view {
  // width: 750px;
  margin-top: 1rem;
  max-height: 699px;
  // width: 100%;
  .admin-search-view-no-result-found {
    color: rgba(41, 47, 65, 0.5);
    font-size: 0.875rem;
    letter-spacing: 0.2px;
    line-height: 1rem;
  }

  // overflow-y: auto;
  .admin-search-view-query {
    padding-left: 0.75rem;
    font-size: 0.75rem;
    color: rgba(41, 47, 65, 0.5);
    letter-spacing: 0.8px;
    text-align: left;
    line-height: 0.75rem;
    text-transform: uppercase;
    margin-bottom: 0.75rem;
  }
  .admin-search-view-content {
    margin-top: 0.75rem;
  }
}
@media only screen and (max-width: 450px) {
  .admin-search-view {
    max-width: auto;
    margin-top: 0rem;
    .admin-search-view-query {
      display: none;
    }
    .tab-pane-container {
      padding: 0;
      .mai-tabs-pane {
        font-size: 12px !important;
        margin: 0 !important;
      }
    }
  }
}
