.auth-wrapper {
  width: 100%;
  min-height: 100vh;

  background: var(--primary_gradient) center center/cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: space-between;
  .auth-wrapper-children {
    padding-top: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.screen-signup {
  // .auth-form-wrapper-container {
  //   width: 580px !important;
  //   padding-top: 30px !important;
  // }
  .signup-success-message {
    padding: 0.5rem 2rem;
  }
}

.auth-wrapper-links {
  // position: absolute;
  // bottom: 0;
  margin-bottom: 24px;
  margin-top: 24px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.8px;
  text-align: center;
  width: 100%;
  a {
    color: rgba(255, 255, 255, 0.5);
    margin-right: 0.5rem;
    text-transform: uppercase;
    &:not(:last-of-type)::after {
      padding-left: 0.5rem;
      content: "|";
    }
  }
}
