.campaign-list {
  .MuiSvgIcon-root{
    &.MuiTableSortLabel-icon {
      opacity: 0.5;
    }
  }
}
.campaign-list-wrap {
  &.auth-children {
    padding: 24px;
  }

  .table-skeleton {
    td {
      padding: 8px 0;
      min-width: 220px;
      & > span {
        //min-width: 380px;
        //max-width: 380px;
        height: 58px;
        margin-right: 24px;
      }

      &:first-child {
        & > span {
          min-width: 520px;
          max-width: 380px;

        }
        padding-left: 24px;
        min-width: 430px;

      }

      &:last-child {
        min-width: 45px;
      }
    }
  }
}
.report-list-filter {
  height: 76px;
  padding: 21px 24px 19px 24px;
  background-color: #FFFFFF;

  & > svg {
    margin-top: 5px;
  }

  & > div {
    font-size: 14px;
    line-height: 36px;
    margin-right: 20px;
    height: 38px;
    font-weight: 700;

    & > div {
      font-weight: 700;
    }
    & > div:focus {
      background-color: transparent !important;
    }
  }
  .report-list-filter-dimensions-label {
    float: right;
    text-align: center;
    line-height: 36px;
    font-size: 14px;
    margin: 0 8px 0 0;
    padding-left: 30px;
    border-left: 1px solid #E6E9ED;
  }

  .campaign-list-filter-date-label {
    float: right;
    text-align: center;
    line-height: 36px;
    font-size: 14px;
    margin: 0 8px 0 0;
  }

  .report-list-filter-search-input {
    margin-left: 16px;
    & input {
      font-weight: 400;
      font-size: 18px;

      &::placeholder{
        color: #8F929B;
      }
    }
  }
  .report-list-filter-csv {
    font-size: 14px;
    font-weight: 600;
    line-height: 36px;
    color: #326AFC;
    cursor: pointer;
    text-align: center;
    border-left: 1px solid #E6E9ED;
    float: right;
    padding: 0 30px;
    margin: 0;
  }
}