#modal-root {
  &>div{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .4);
  }
  .overlay {
    z-index: 111 !important;
  }
  .success-modal{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 100px;
    left: 50%;
    min-width: 654px;
    border-radius: 24px;
    transform: translateX(-50%);
    background: #fff;
    overflow-y: inherit;
    border: 200px;

    .success-modal-content{
      flex: 1 1;

      .text-center {
        text-align: center !important;
      }
      .api-response-modal-heading-success {
        //background-image: url("../../../assets/images/success_background.png");
        background-color: #6ca1f2;
      }
      .api-response-modal-heading {
        background-size: cover;
        height: 80px;
        padding-top: 24px;
        color: #7B7D84;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: .5px;
        text-transform: uppercase;
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
        background-blend-mode: hard-light;

        .api-response-message {
          height: 27px;
        }
        .api-response-icon-container {
          height: 80px;
          width: 80px;
          background-color: #fff;
          border: 5px solid #dfe7f0;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          margin: 0 auto;
        }
      }
      .api-response-modal-content {
        padding-top: 20px;

      }
      .api-response-modal .mai-button {
        margin-bottom: 44px;
      }
      .mai-button.fill-blue {
        background-color: #3a77eb;
        width: 30%;
        border-radius: 24px;
      }
      .mai-button.disabled {
        background-color: #e9ecef;
        width: 30%;
        border-radius: 24px;
      }
      .grey-border {
        fieldset {
          border-color: #0000003b;
        }

        .Mui-focused{
          fieldset {
            border-color: #3a77eb;
          }
        }
      }
    }
  }
}