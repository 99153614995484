.mai-footer {
  .mai-footer-inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1.5rem 0;
    margin-left: 5.5rem;
  }

  .mai-footer-info {
    color: #292f41;
  }

  a {
    text-decoration: none;
    color: #292f41;
  }

  .mai-footer-copyright {
    color: #292f41;
    opacity: 0.5;
  }

  @media screen and (max-width: 1080px) {
    padding-top: 5%;
  }

  @media screen and (max-width: 460px) {
    padding-top: 6%;
    .mai-footer-inner {
      margin-left: 0;
    }
  }

  .mai-footer-link:hover {
    text-decoration: underline;
    transition: all 0.3s;
  }
}
