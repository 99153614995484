@property --progress {
  syntax: "<number>";
  inherits: false;
  initial-value: 0;
}

.events-page {
  display: flex;
  height: calc(100vh - 156px);

  .events-wrap {
    width: 17.5%;
    height: 100%;
    margin-right: 6px;

    .events-total {
      margin: 20px 0 30px calc(52% - 31px);
      width: 75px;
      height: 75px;
      border-radius: 50%;
      background-color: #FFFFFF;
      position: relative;
      text-align: center;
      padding-top: 25px;


      .circular-progress {
        position: absolute;
        left: 0;
        top: 0;
        --size: 75px;
        --half-size: calc(var(--size) / 2);
        --stroke-width: 2px;
        --radius: calc((var(--size) - var(--stroke-width)) / 2);
        --circumference: calc(var(--radius) * 3.14 * 2);
        --dash: calc((var(--progress) * var(--circumference)) / 100);
        animation: progress-animation 5s linear 0s 1 forwards;
      }

      .circular-progress circle {
        cx: var(--half-size);
        cy: var(--half-size);
        r: var(--radius);
        stroke-width: var(--stroke-width);
        fill: none;
        stroke-linecap: round;
      }

      .circular-progress circle.bg {
        stroke: #ddd;
      }

      .circular-progress circle.fg {
        transform: rotate(-90deg);
        transform-origin: var(--half-size) var(--half-size);
        stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
        transition: stroke-dasharray 0.3s linear 0s;
        stroke: #5394fd;
      }
    }

    .events-container {
      width: 100%;
      height: calc(100% - 123px);
      overflow: auto;


      &::-webkit-scrollbar {
        width: 3px;

      }

      &::-webkit-scrollbar-track {
        //background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #D9D9D9;
        border-radius: 3px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #D9D9D9;
      }

      .event-row {
        display: flex;
        width: 100%;
        //border: 1px solid red;
        margin-bottom: 3px;
        //line-height: 33px;
        height: 33px;

        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 33px;
          color: #8F929B;
          width: calc(52% - 10px);
          padding-left: 16px;
        }

        .circle {
          width: 33px;
          height: 33px;
          border-radius: 50%;
          background-color: #FFFFFF;
          text-align: center;
          line-height: 31px;
          border: 1px solid #5394fd;
          cursor: pointer;

          &.selected {
            border-color: #FFFFFF;
          }
        }
      }

      .empty-row {

        display: flex;
        margin-bottom: 3px;

        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 16px;
          color: #8F929B;
          width: 52%;
          padding-left: 16px;
        }

        .circle {
          width: 13px;
          height: 13px;
          border-radius: 50%;
          background-color: #BFD0E3;
          margin-top: 3px;
        }
      }

      .event-separator {
        display: block;
        width: calc(52% + 7px);
        height: 20px;
        margin-bottom: 3px;
        border-right: 1px solid #BFD0E3;
      }
    }
  }

  .events-details-wrap {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: calc(100% + 24px);
    background-color: #FFFFFF;
    border-radius: 16px;
    padding: 16px 24px 0;

    .events-details-head {

      .events-details-head-select {
        font-size: 12px;
        font-weight: 600;
        min-width: 30px;
        background-color: transparent;
        margin-right: 30px;
        line-height: 36px;
        height: 36px;
        float: left;

        & div:focus{
          background-color: transparent;
        }
      }
      .play-pause {
        background-color: #E6E9ED;
        min-Width: 55px;
        height: 36px;
        float: right;
        border-radius: 20px;
        align-self: center;
        box-shadow: none;

        & span {
          margin: 0;
          & svg {
            fill: #8F929B;
          }
        }
      }
    }

    .events-details-head-new {
      width: 100%;
      height: 73px;
      display: flex;
      justify-content: center;

      .circle{
        width: 75px;
        height: 75px;
        border-radius: 50%;
        background-color: #326AFC;
        border: 2px solid rgba(50, 106, 252, 0.35);
        position: relative;
        text-align: center;
        padding-top: 8px;
        color: #FFFFFF;

        .count {
          font-size: 28px;
          font-weight: 400;
          line-height: 35px;
        }
        .text {
          font-size: 12px;
          font-weight: 600;
          line-height: 16px;
        }
      }
    }

    .events-details-body {

      width: 100%;
      height: calc(100% - 123px);
      overflow: auto;


      &::-webkit-scrollbar {
        width: 3px;

      }

      &::-webkit-scrollbar-track {
        //background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #D9D9D9;
        border-radius: 3px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #D9D9D9;
      }
    }


    .event-item-wrap {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      .separator {
        width: 2px;
        height: 14px;
        margin: 3px 0;
        background-color: #E6E9ED;
      }
      .event-item {
        width: 100%;
        display: flex;
        justify-items: center;

        .event-time {
          width: calc((100% - 30px) / 2);
          text-align: right;
          padding-right: 40px;
          color: #8F929B;
          font-weight: 400;
          font-size: 16px;
        }
        .event-icon, .event-icon-bug {
          width: 31px;
          height: 30px;
          border-radius: 50%;
          background-color: #E9EFFF;
          color: #5A7BFD;
          text-align: center;
          padding-top: 6px;

          & svg {
            font-size: 18px;
          }
        }
        .event-icon-bug {
          background-color: #FFD2C1;
          color: #FF7B4B;
        }
        .event-name {
          width: calc((100% - 30px) / 2);
          padding-left: 40px;
          color: #292F41;
          font-weight: 400;
          font-size: 16px;
        }
      }
    }

  }

  .event-info-wrap {
    display: flex;
    flex-direction: column;
    width: 32%;
    height: 100%;
    padding-left: 24px;

    .event-info,.event-custom-params {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 250px;
      padding: 20PX 24px;
      border-radius: 16px;
      background-color: #FFFFFF;
      margin-bottom: 12px;

      .title{
        font-weight: 400;
        font-size: 14px;
        color: #292F41;
        margin-bottom: 10PX;
      }
      .text {
        font-weight: 400;
        font-size: 12px;
        color: #8F929B;
      }
    }

    .event-info {
      .title{
        margin-bottom: 5PX;
      }
      .name-label, .name-text {
        width: 100%;
        font-weight: 400;
        font-size: 20px;
        color: #8F929B;
        margin-bottom: 10px;
      }
      .name-text {
        color: #292F41;
      }

      .detail-row {
        display: flex;

        .detail-label, .detail-value {
          width: 40%;
          height: 21px;
          font-weight: 400;
          font-size: 14px;
          color: #8F929B;
          line-height: 16px;
          white-space: nowrap;

          &.url {
            width: 100%;
            white-space: normal;
          }
        }
        .detail-value {
          width: 60%;
          color: #292F41;
          text-overflow: ellipsis;
          overflow: hidden;

          &.url {
            white-space: normal;
            height: 50px;
          }
        }

        &.url {
          flex-direction: column;
        }
      }
    }

    .event-custom-params {
      height: 95px;
    }
  }
}

.slide-down-true {
  position: relative;
  animation-name: slide-2;
  animation-duration: 1s;
  animation-delay: 250ms;
}
.slide-down-false {
  position: relative;
  animation-name: slide-1;
  animation-duration: 1s;
  animation-delay: 250ms;
}

@keyframes slide-2 {
  0%   {top:0;}
  100% {top:59px;}
}

@keyframes slide-1 {
  0%   {top:0;}
  100% {top:39px;}
}
