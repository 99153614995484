.custom-scroll {

  &::-webkit-scrollbar {
    width: 8px !important;
  }

  &::-webkit-scrollbar-track {
    //background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #D9D9D9 !important;
    border-radius: 3px !important;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #D9D9D9 !important;
  }
}