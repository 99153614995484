//@import "../../globalVariables.scss";

.mai-content-wrapper {
  background: #ffffff;
  box-shadow: 0 12px 8px -10px rgba(0, 0, 0, 0.12);
  width: 100%;
  padding: 15px 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  border: 1px solid #dfe7f2;
  padding-top: 0;
  &.content-close {
    padding: 0 10px;
  }
  .mai-content-wrapper-description {
    padding: 0 15px;
    color: rgba(41, 47, 64, 0.5);
    font-size: 18px;
    line-height: 24px;
  }
  &.content-center {
    display: flex;
    justify-content: center;
  }
  .header {
    border-bottom: 1px solid #f4f6fa;
    padding: 12px 15px;
    margin: 8px 0;
    font-size: 1.5rem;
    font-weight: 200;
    letter-spacing: -0.2px;
    line-height: 1.75rem;
    &.no-border {
      border-bottom: none;
    }
    &.close {
      cursor: pointer;
    }
  }
  .app-switcher-container {
    margin-bottom: 0;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #f4f6fa;
    &.content-right {
      justify-content: flex-end;
    }
    @media screen and (max-width: 460px) {
      flex-direction: column;
    }
  }
  .app-switcher-container {
    align-items: center;
    .app-switcher-title {
      font-size: 12px;
      color: rgba(41, 47, 65, 0.5);
      letter-spacing: 0.8px;
      text-align: left;
      line-height: 12px;
      text-transform: uppercase;
    }
  }
  .mai-content-wrapper-collapse-icon {
    .mai-content-wrapper-arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      &.active {
        img {
          transform: rotate(180deg);
          transition: 0.2s all ease;
        }
      }
    }
  }
  .mai-content-wrapper-children {
    // margin-top: 1.75rem;
  }
}

