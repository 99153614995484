.campaign-list-item-title {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  .status-tooltip {
    position: relative;
    display: inline-block;
  }
  .status-tooltip .status-tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: rgb(58, 119, 235);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }
}

.campaign-list-item-intro {
  display: inline-block;
  margin-left: 35px;
  width: 75%;

  .campaign-list-item-name {
    font-weight: 600;
    display: flex;
    width: 100%;
  }
  .campaign-name {
    text-overflow: ellipsis;
    overflow: hidden;
    height: 100%;
    width: 100%;
    white-space: pre;
    color: #292f41;
    .name {
      font-size: 1.25rem;
      line-height: 1.25rem;
      margin-bottom: 0.2rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .status {
      font-size: 0.875rem;
      line-height: 1rem;
      font-weight: 400;
    }
    .campaign-list-item-status-text.status-0 {
      color: gray;
    }
    &.status-0 {
      color: black;
    }
    .campaign-list-item-status-text {
      display: block;
      text-transform: uppercase;
      &.status-2 {
        color: #e14b4b;
      }
      &.status-3 {
        color: #6cd387;
      }
      &.status-1 {
        color: #ef902e;
      }
      &.status-6 {
        color: #ef902e;
      }
    }
  }
  .campaign-list-star {
    // height: 1.2vw;
    width: 100%;
    background-image: url(../../../../../../assets/images/favorite_star_filled.svg);
    background-size: contain;
    background-position: center;
    margin-left: 10px;
    background-repeat: no-repeat;
  }
}

.campaign-list-item-status {
  position: absolute;
  height: 16px;
  width: 16px;
  display: inline-block;
  border-style: solid;
  border-width: 2px;
  border-radius: 100%;
  border-color: #dfe7f0;
  top: -10px;
}
