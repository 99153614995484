.dsp-sidebar {
  min-height: 100vh;
  text-transform: uppercase;
  display: inline;
  .side-bar-item-link {
    &.disabled {
      cursor: auto;
      svg, span {
        opacity: 0.4;

      }
    }
  }
  .dsp-sidebar-container {
    z-index: 1300;
    position: fixed;
    height: 100%;
    max-width: 88px;
    width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
    background: #ffffff;

    .sidebar-help {
      bottom: 0;
      left: 0;
      display: block;
      background-color: #ffffff;
      position: fixed;
      height: 72px;
      width: 89px;
      padding: 15px 28px;
    }
  }
  .dsp-logo-container {
    width: 100%;
    height: 88px;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--main-logo), var(--primary_gradient);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  .dsp-sidebar-routes {
    padding: 0 4px;

  }
  .dsp-sidebar-item {
    width: 100%;
    height: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    span {
      color: rgba(41, 47, 65, 0.5);
      font-size: 12px;
      letter-spacing: 0.8px;
      line-height: 12px;
      text-align: center;
      margin-top: -12px;

      &.dsp-sidebar-label{
        color: #3A77EB;
      }
    }
    img {
      width: 25px;
      height: 25px;
    }
  }
  .dsp-sidebar-my-profile {
    img {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      border: 2px solid #dfe7f0;
      box-shadow: 0 16px 12px -12px rgba(88, 47, 117, 0.5);
    }
    span {
      margin-top: 0;
    }
  }
  .dsp-sidebar-separator {
    height: 1px;
    width: 100%;
    border-radius: 0.5px;
    background-color: rgba(41, 47, 65, 0.2);
  }
  .dsp-sidebar-active path:last-child {
    fill: #3A77EB;
    fill-opacity: 1;
  }
  .dsp-sidebar-active-all-fill path {
    fill: #3A77EB;
    fill-opacity: 1;
  }
}
@media only screen and (max-width: 450px) {
  .dsp-sidebar {
    min-height: auto;
    .is-active {
      bottom: -80px !important;
    }
    .dsp-sidebar-container {
      position: fixed;
      bottom: 200%;
      max-width: 100%;
      padding: 0 1rem;
      transition: 0.2s;

      .dsp-sidebar-icon-wrapper {
        svg {
          width: 2.1rem !important;
          height: 2.1rem !important;
        }
      }
      .dsp-logo-container {
        display: none;
      }
    }
    .dsp-sidebar-my-profile {
      img {
        width: 2.1rem;
        height: 2.1rem;
      }
    }
    .dsp-sidebar-item {
      justify-content: initial;
      flex-direction: initial;

      span {
        font-size: 1.1rem;
        margin-left: 1.5rem;

      }
      .dsp-sidebar-label {
        color: var(--primary_color);
        font-weight: 600;
      }
    }
  }
}
