.advanced-bidding-wrap {
  width: 100%;
  //max-width: 680px;

  .advanced-bidding-header {
    display: flex;
    justify-content: space-between;
    height: 36px;
    align-items: center;
    margin-bottom: 15px;

    .title {
      color: #292F41;
      font-size: 24px;
      font-weight: 400;
    }

    .add-bidding {
      display: flex;

      p {
        color: #326AFC;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
        line-height: 36px;
        margin-right: 12px;
      }

      .add {
        min-width: 55px;
        height: 36px;
        float: right;
        border-radius: 20px;
        align-self: center;
        box-shadow: none;
        background-color: #326AFC;

        & span {
          margin: 0;
        }
      }
    }
  }

  .advanced-bidding-body {
    .bidding-item-wrap {
      margin-top: 18px;
      .bidding-item-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;

        .name {
          cursor: pointer;
          display: flex;
          padding-right: 24px;

          p {
            color: #292F41;
            font-size: 18px;
            font-weight: 600;
            line-height: 36px;
          }
          .edit-icon {
            display: none;
          }

          &:hover {
            .edit-icon {
              display: inline-block;
              color: #326AFC;
              font-size: 18px;
              margin-top: 9px;
              margin-left: 30px;
            }
          }
        }

        .name-input, .multiplayer-input {
          height: 36px;
          input {
            padding: 8px 16px;
            font-size: 14px;
          }
          & fieldset {
            border-radius: 8px;
            border: 1px solid #E3E4E5;
          }
        }
        .name-input {
          width: 562px;
          .endAdornment {
            display: none;
          }

          &:hover {
            .endAdornment {
              cursor: pointer;
              display: inline-block;
              color: #326AFC;
            }
          }
        }

        .multiplayer-input {
          width: 146px;
        }

        .remove {
          color: #7B7D84;
          font-size: 14px;
          line-height: 36px;
          text-transform: none;
          padding: 0 0 0 15px;

          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }

  .rules-group-container {
    border-radius: 12px;
    background-color: #F4F6FA;
    border-color: #E3E4E5;

    //&.has-error {
    //  .rules-group-header {
    //    .error-container {
    //      &[title='The group is empty'] {
    //        display: none !important;
    //      }
    //    }
    //  }
    //}

    .rules-group-header {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;

      .group-conditions {
        position: absolute;
        left: 10px;

        label {
          background-color: #326AFC;
          padding: 6.2px 12px;
          height: 30px;
          line-height: 30px;
          color: #FFFFFF;
          font-size: 14px;
          font-weight: 600;
          cursor: pointer;

          &.active {
            background-color: #1952e8;
          }

          &:nth-child(1) {
            border-bottom-left-radius: 8px;
            border-top-left-radius: 8px;
            border-right: 1px solid rgba(255, 255, 255, .1);
          }

          &:nth-child(2) {
            border-bottom-right-radius: 8px;
            border-top-right-radius: 8px;
          }
        }
      }

      .group-actions {
        display: flex;
        align-items: center;

         button {
           width: 30px;
           height: 30px;
           overflow: hidden;
           color: transparent;
           border-radius: 8px;
           background-color: #B1C3D6;
           border: none;
           margin-left: 4px;
           cursor: pointer;

           &:nth-child(1) {
             background-image: url('../../../../assets/images/add_layer.png');
           }
           &:nth-child(2) {
             background-image: url('../../../../assets/images/add_group.png');
           }
           &:nth-child(3) {
             background-image: url('../../../../assets/images/close.png');
           }
        }
      }
    }

    .rules-group-body {

      .rules-list {

        .rule-container {

          .rule-header {

            .rule-actions {
              button {
                float: right;
                width: 30px;
                height: 30px;
                overflow: hidden;
                color: transparent;
                border-radius: 8px;
                background-color: #FFFFFF;
                border: none;
                cursor: pointer;
                background-image: url('../../../../assets/images/close_black.png');
              }
            }
          }
          .rule-filter-container,
          .rule-operator-container,
          .rule-value-container {

            margin: 0 10px 0 0;

            select, input {
              border: 1px solid #E3E4E5;
              height: 36px;
              width: 200px;
              border-radius: 8px;
              padding-left: 8px;

              &:focus-visible {
                border: 2px solid #E3E4E5;
                outline: none !important;
              }
            }
            input {
              &[type="radio"] {
                width: 20px;
                height: 14px;
                margin-top: 5px;

                &[value='No'] {
                  margin-left: 20px;
                }
              }
            }
          }
          .rule-value-container {
            padding-left: 12px;
          }
        }
      }
    }
  }
}

.padding_50_0_40 {
  &.auth-children {
    padding: 24px 40px 0 50px;
  }

  .page-container {
    width: 100%;
  }
}