.admin-accounts-totals {
  display: flex;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #cdd5de;
  .dashboard-stats {
    min-height: auto;
    &:not(:last-child) {
      margin-right: 1rem;
    }
    .loader-wrapper {
      .loader-container {
        padding-top: 1rem;
      }
    }
  }
}
@media only screen and (max-width: 450px) {
  .admin-accounts-totals {
    flex-direction: column;
    .dashboard-stats {
      margin-top: 0.5rem;
    }
  }
}
