.MuiButtonBase-root {
  &.MuiMenuItem-root {
    &.MuiMenuItem-gutters {
      .campaign-name {
        margin-left: 24px;
      }
      .campaign-status-text {
        color: #6CD387;
        margin-left: 24px;
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
      }
    }
  }
}

.MuiSelect-select {
  &.MuiSelect-outlined {
    &.MuiInputBase-input {
      &.MuiOutlinedInput-input {
        .campaign-status-indicator,
        .campaign-status-text {
          display: none;
        }
      }
    }
  }
}