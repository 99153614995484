.date-section-date {
  display: flex;
  margin-left: 12px;
  // min-width: 300px;
  @media screen and (max-width: 460px) {
    margin-left: 0;
  }
  .mai-form-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.data-red-border {
  border: 1px solid red;
}
.react-datepicker-wrapper {
  width: 100%;
  div {
    width: 100%;
  }
}

.react-datepicker__input-container {
  input {
    min-height: 42px;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #292f41;
    font-size: 18px;
    line-height: 24px;
    padding: 0 10px;
    outline: 0;
    transition: all 0.2s ease-out;
    font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;

    border: 1px solid #dfe7f0;
    border-radius: 4px;
    width: 100%;
  }
  height: 100%;
}

.datepicker-label {
  width: 85px;
  font-size: 14px;
  background-color: #ffffff;
  color: rgba(41, 47, 65, 0.5);
  border: 1px solid #dfe7f0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  box-sizing: border-box;
  // display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  height: 100%;
  align-items: center;
  padding-left: 0.5rem;
  .datepicker-label-remove-icon {
    margin-right: 0.5rem;
  }
}

.datepicker {
  box-sizing: border-box;
  color: #292f41;
  background-color: #ffffff;
  font-size: 18px;
  // border-left: none !important;
  border: 1px solid #dfe7f0;
  border-radius: 4px;
  cursor: pointer;
  height: 100%;
  display: flex;
  padding: 0 0.7rem;
  justify-content: space-between;
  align-items: center;
  min-height: 42px;
}

.datepicker-value-date {
  margin-right: 12px;
  color: #bfbfbf;
  &.selected {
    color: #292f41;
  }
}

.datepicker-icon {
  width: 24px;
  height: 24px;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
  background: var(--primary_color) !important;
  transition: all 0.3s;
  // background-color: ;
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range,
.react-datepicker__month--selecting-range
.react-datepicker__month-text--in-range,
.react-datepicker__month--selecting-range
.react-datepicker__quarter-text--in-range {
  background: var(--secondary_color) !important;
  transition: all 0.3s;
  // opacity: 0.8;
  color: #ffffff !important;
}

.react-datepicker__month--selected:hover,
.react-datepicker__month--in-selecting-range:hover,
.react-datepicker__month--in-range:hover,
.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter--in-selecting-range:hover,
.react-datepicker__quarter--in-range:hover {
  background: var(--gauge_color) !important;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover {
  background: var(--gauge_color) !important;
}

.react-datepicker__day--range-end,
.react-datepicker__day--range-start {
  background: var(--gauge_color) !important;
}

.react-datepicker__day--disabled {
  cursor: not-allowed !important;
}
