.admin-default-view {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  .admin-default-view-container {
    margin-right: 1rem;
    min-width: 340px; // TODO: Fix
    width: 100%;
    &.campaigns {
      margin-right: 0;
    }
  }
  .admin-default-view-not-found {
    margin-top: 0.5rem;
    margin-top: 7rem;
    padding: 0.375rem 1rem;
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.875rem;
    letter-spacing: 0.2px;
    line-height: 1rem;
    margin-bottom: 1rem;
    white-space: pre-line;
    line-height: 1.2rem;
    text-align: center;
    span {
      display: block;
      margin-bottom: 0.5rem;
      &:first-child {
        font-size: 1rem;
        font-weight: 600;
      }
      &:last-child {
        font-size: 0.75rem;
        color: rgba(125, 99, 104, 0.5);
      }
    }
  }
  .admin-default-view-title {
    padding: 0.75rem 1rem;
    background-color: #dfe7f0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    font-size: 0.75rem;
    color: rgba(41, 47, 65, 0.5);
    letter-spacing: 0.8px;
    text-align: left;
    line-height: 0.75rem;
    text-transform: uppercase;
  }
  // .admin-default-view-campaigns,
  // .admin-default-view-accounts {
  //   margin: 0.75rem 1rem;
  // }

  .admin-default-campaign,
  .admin-default-account {
    background-color: #ffffff;
    padding: 0.375rem 1rem;
    // min-height: 75px;
    display: flex;
    justify-content: center;
    position: relative;
    &::after {
      bottom: 0;
      // left: 20%;
      position: absolute;
      content: "";
      height: 1px;
      width: calc(100% - 2rem);
      background-color: #dfe7f0;
      display: block;
      border-bottom: 1px solid #dfe7f0;
    }
  }
  .admin-default-account {
    &::after {
      width: calc(100% - 2rem - 48px);
      left: 64px;
    }
    display: flex;
    // justify-content: space-between;
    align-items: center;
    .admin-default-account-image {
      width: 48px;
      height: 48px;
      img {
        border-radius: 50%;
        width: 48px;
        height: 48px;
        border: 4px solid #dfe7f0;
      }
      margin-right: 0.75rem;
    }
    .admin-default-account-text-name {
      font-weight: 600;
      font-size: 1rem;
      color: #292f41;
      letter-spacing: 0;
      text-align: left;
      line-height: 1.5rem;
    }
    .admin-default-account-text-date {
      font-size: 0.75rem;
      color: rgba(41, 47, 65, 0.5);
      letter-spacing: 0.8px;
      text-align: left;
      line-height: 0.75rem;
      text-transform: uppercase;
    }
    .admin-default-account-icon {
      margin-left: 2rem;
      flex: 1;
      img {
        float: right;
      }
    }
  }

  .admin-default-campaign {
    flex-direction: column;
    .admin-default-campaign-name {
      font-weight: 600;
      font-size: 1rem;
      color: #292f41;
      letter-spacing: 0;
      text-align: left;
      line-height: 1.5rem;
      margin-top: 0.3rem;
    }
    .admin-default-campaign-date {
      font-size: 0.75rem;
      color: rgba(41, 47, 65, 0.5);
      letter-spacing: 0.8px;
      text-align: left;
      line-height: 0.75rem;
      text-transform: uppercase;
      margin-bottom: 0.4375rem;
    }
  }
}
@media only screen and (max-width: 450px) {
  .admin-default-view {
    li {
      max-width: none !important;
    }
    margin-top: 0;
    margin-right: 0;
    max-width: none;

    .admin-default-view-container {
      width: 100%;
      margin: 0;
      height: 539px;
      .admin-default-view-accounts {
        height: 100%;
        max-height: 58vh;
      }
    }
  }
}
