.admin-accounts-filter-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  .admin-accounts-filter-left {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;

    & > div {
      min-width: 350px;
      margin-right: 24px;
      margin-bottom: 24px;

      &.MuiFormControl-root {
        &.MuiTextField-root {
          height: fit-content;
        }
      }
    }
  }

  .admin-accounts-filter-right {
    min-width: 350px;
    flex-direction: column;

    .admin-accounts-filter-right-buttons {
      .accounts-container-graph-download-excel {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
}