.admin-moderation-tabs-header {
  button {
    padding: 0.8rem 1.4rem 0.8rem 1.5rem;
    box-sizing: border-box;
    height: 100%;
    display: inline-block;
    font-size: 1.4rem;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
    border-bottom: 2px solid transparent;
    font-weight: 600;
    position: relative;
    color: inherit;
    text-transform: capitalize !important;
    min-height: 10px !important;

    //&.Mui-selected {
    //  font-weight: 600;
    //  height: 100%;
    //  color: rgba(255, 255, 255, 0.9);
    //  background: var(--primary_gradient);
    //}
  }
}


.admin-moderation-tab {
  margin-top: 1rem;
  .mai-content-wrapper {
    margin-top: 1rem;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
  .mai-table-container {
    margin-top: 0;
    box-shadow: none;
  }
}
