.card-list-wrapper {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 600px) {
  .card-list-wrapper {
    justify-content: center;
  }
}
