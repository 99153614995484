.accounts-moderation-table-wrapper {
  .mai-table {
    tbody {
      tr {
        height: 3.5rem;
      }
    }
  }
}

.tooltip-container{
  position: relative;

  svg {
    position: absolute;
    top: 2px;
    left: 0;
  }
}
