.selectAudience {
  display: flex;
  flex-direction: column;
  width: 370px;
  height: 100%;
  border-right: 1px solid #E3E4E5;
  .title {
    line-height: 102px;
    margin-left: 40px;
    font-size: 24px;
    font-weight: 600;
  }
  .item {
    display: flex;
    width: 100%;
    height: 112px;
    cursor: pointer;
    .icon {
      position: relative;
      width: 30px;
      height: 30px;
      margin: 34px 50px 34px 30px;
      border-radius: 50%;
      background-color: #E3E4E5;

      img {
        width:18px;
        height: 18px;
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
      }
    }
    .item-text {
      width: 70%;
      .item-title {
        margin-top: 33px;
        line-height: 22px;
        font-size: 18px;
        font-weight: 600;
        color: #292F41;
      }

      .item-description {
        line-height: 15px;
        font-size: 12px;
        font-weight: 400;
        color: #7B7D84;
      }
    }

    &.selected {
      width: 100%;
      height: 112px;
      background-color: #F4F6FA;
      .icon {
        position: relative;
        width: 70px;
        height: 70px;
        margin: 21px 16px 21px 30px;
        border-radius: 50%;
        background-color: #E3E4E5;

        img {
          width: 33px;
          height: 33px;
          position: absolute;
          transform: translate(-50%, -50%);
          left: 50%;
          top: 50%;
        }
      }
      .item-text {
        width: 70%;
        .item-title {
          margin-top: 33px;
          line-height: 20px;
          font-size: 16px;
          font-weight: 600;
          color: #292F41;
        }

        .item-description {
          line-height: 15px;
          font-size: 12px;
          font-weight: 400;
          color: #7B7D84;
        }
      }
    }
  }
}
.dynamic-audience {
  position: relative;
  width: calc(100% - 371px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F4F6FA;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;

   img {
     width: 52%;
   }

  .dynamic-audience-description,
  .dynamic-audience-description-1 {
    font-size: 16px;
    font-weight: 400;
    color: #292F41;
    margin: 0 60px;
  }
  .dynamic-audience-description-1 {
    margin-top: 30px;
  }
  .dynamic-audience-btn {
    position: absolute;
    right: 28px;
    bottom: 22px;
  }
  .dynamic-audience-close-btn {
    position: absolute;
    right: 12px;
    top: 8px;
  }
}