.admin-drawer-links {
  transition: var(--transition);
  &.active {
    left: -12rem !important;
    transition: var(--transition);
  }
  .admin-sidebar-moderation-link {
    display: flex;
    // justify-content: sp;
    .admin-sidebar-moderation-link-count {
      margin-left: 0.5rem;
      background-color: var(--gauge_color);
      font-size: 0.9rem;
      width: 22px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
    }
  }
  .toggle-arrow {
    cursor: pointer;
    width: 32px;
    height: 32px;
    position: absolute;
    z-index: 11111;
    border-radius: 50%;
    border: 2px solid #dfe7f0;
    background: white;
    right: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    .int-icon {
      fill: var(--primary_color);
      width: 11px;
      height: 11px;
      transform: rotate(180deg);
    }
  }
}
.admin-drawer-links-list {
  li {
    padding: 0!important;
    &:hover {
      opacity: 0.6;
    }
    .admin-icon {
      transform: rotate(270deg);
      width: 12px;
      height: 12px;
      margin-left: 1rem;
    }

    a {
       padding: 1.2rem 1rem;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 500px) {
    .admin-drawer-links {
    .toggle-arrow {
    display: none;
   }
  }
}
