.audience-details-wrap {
  height: calc(100vh - 136px);
  background-color: #ffffff;

  .audience-details-header {
    display: flex;
    min-height: 42px;
    align-items: center;
    padding: 12px 16px;
    border-bottom: solid 1px #0000003b;

    .audience-details-header-title {
      width: 60%;
      font-size: 18px;
      font-weight: 600;
    }
    .audience-details-header-actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 40%;

      .navigate,.download, .send {
        font-size: 16px;
        font-weight: 400;
        cursor: pointer;
        min-width: 42px;
      }
      .navigate, .send {
        color: #3a77eb;
      }
      .navigate {
        display: flex;
        align-items: center;
      }
      .download {
        line-height: 12px;
      }
    }
  }

  .audience-details-body {
    padding: 16px;

    .block-header {
      border-bottom: solid 1px #e3e4e5;
      margin-bottom: 16px;
      padding-bottom: 4px;

      p {
        font-size: 18px;
        font-weight: 600;
        margin-right: 42px;

      }
    }

    .general {
      .block-header {
        border-bottom: solid 1px #e3e4e5;
        margin-bottom: 16px;
        padding-bottom: 4px;
        display: flex;
        align-items: center;

        p {
          font-size: 18px;
          font-weight: 400;

          &.title-label{
            font-weight: 700;
            font-size: 17px;
            margin-right: 16px;
          }
        }
      }
    }

    .block-body {
      display: flex;
      margin-bottom: 36px;

      .left {
        display: flex;
        flex-direction: column;
        //margin-right: 2%;
        width: 100%;

        .general-block-row {
          display: flex;
          border-bottom: solid 1px #e9ecef;

          .general-block-item {
            display: flex;
            padding: 4px 8px;
            margin-right: 12px;
            width: 50%;

            .item-label {
              margin-right: 10px;
              font-weight: 700;
              font-size: 14px;
            }
            .item-text {
              font-weight: 400;
              font-size: 14px;
            }
          }
        }
      }

      .core-table-wrap {
        width: 100%;

        .table-container {
          margin-top: 0;
          min-height: 30px;
        }
      }

      .core-table-wrap {
        &.no-table-data {
          .table-container {
            table {
              min-height: 330px !important;
            }
          }
        }
      }

    }
  }
}

