.action-item-wrap {
  position: relative;
  display: flex;

  .action-item {
    width: 660px;
    border: 1px solid #E3E4E5;
    border-radius: 12px;

    .action-item-header {
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      position: relative;
      padding: 12px 116px 12px 20px;
      background: #F4F6FA;
      border-radius: 12px;

      .action-item-header-text {
        margin-right: 10px;
        font-size: 14px;
        line-height: 17px;
      }

      &.border-bottom {
        border-bottom: 1px solid #E3E4E5;
      }

      .expand {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 14px;
        line-height: 20px;
        color: #326AFC;
        cursor: pointer;

      }

    }
    .action-item-body {
      padding: 10px 20px;

      .action-item-body-title {
        float: left;
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 10px;
      }
    }
  }
  .remove-item {
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin-top: 15px;
  }

  .remove-parameter {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .add-parameter {
    fill: #9497a0;
    margin: 0 8px;
    cursor: pointer;
    font-size: 1.2rem;
  }
  .action-input {
    width: 164px;
    background-color: #FFFFFF;
    margin-right: 10px;
    border-radius: 8px;

    &>div {
      border-radius: 8px;
    }

    &.small {
      width: 45px;
    }
    & fieldset {
      border-color: #E3E4E5;
    }

    &.error {
      & fieldset {
        border-color: red;
      }
    }

    input {
      font-size: 14px;
      padding: 8px 14px;
    }
  }

  .parameters-item {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 10px;

    .parameters-select {
      border-radius: 8px;
      width: 144px;
      height: 36px;
      padding-left: 16px;
      margin-right: 10px;
      border: 1px solid #E3E4E5;
      background-color: #FFFFFF;

    }
  }
}
