html {
  box-sizing: border-box;
  /* min-width: 1480px;
  overflow: auto; */
  /* font-size: 14px; */
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

:root {
  --primary_gradient: linear-gradient(
    225deg,
    var(--secondary_color) 0%,
    var(--primary_color) 100%
  );
  --font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;

  --api-response-success-background: url("./assets/images/success_background.png");
  --api-response-fail-background: url("./assets/images/fail_background.jpg");
  --primary_gradient-opacity: linear-gradient(
    225deg,
    rgba(101, 171, 246, 0.9) 0%,
    rgba(46, 107, 222, 0.9) 100%
  );
}

/* :root {
  --primary_color: #3a77eb;
  --secondary_color: #6ca1f2;
  --dsp-gradient: linear-gradient(225deg, #65abf6 0%, #2e6bde 100%);

  --gauge_color: #ef902e;
  --warning_color: #e14b4b;
  --auth-background: url("./assets/images/login_bg.webp");
  --sidebar-background: url("./assets/images/sidebar_bg.jpg");
  --main-logo: url("./assets/images/logo_white.svg");
  --sidebar-logo: url("./assets/images/logo_white.svg");
} */

body {
  margin: 0;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  min-height: 100%;
}

#root {
  min-height: 100vh;
}

.loader-container .loading-text {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

.border-shadow {
  height: 40px;
  width: 180px;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0 12px 8px -10px rgba(16, 89, 91, 0.3);
}

a {
  text-decoration: none;
}

.dsp-auth-container {
  display: flex;
  height: 100%;
  min-height: 100vh;
}

.mai-button {
  font-weight: bold;
  white-space: nowrap;
}

.mai-form-control,
.mai-table,
.mai-button {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
}

.mai-table-border-bottom {
  border-bottom: 1px solid #f4f6fa !important;
  width: 98% !important;
}

.mai-table-border-bottom td {
  max-width: 400px;
}

@media screen and (max-width: 450px) {
  .loader-container {
    margin-left: 15px;
  }
}

@media screen and (max-width: 450px) {
  .mai-app-modal {
    width: 350px !important;
  }
  .auth-form-wrapper-container {
    max-width: 350px !important;
  }
}

@media screen and (max-width: 375px) {
  .auth-form-wrapper-container {
    width: 320px !important;
  }
}

@media screen and (max-width: 320px) {
  .auth-form-wrapper-container {
    width: 290px !important;
  }
}

/* .clearfix {
  overflow: auto;
} */

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.mai-content-wrapper .header {
  font-weight: 200;
  font-family: var(--font-family);
  font-size: 24px;
  line-height: 28px;
}

.mai-button.fill-blue {
  background: var(--primary_gradient);
}

.mai-app-modal {
  height: auto !important;
}

.api-response-modal .mai-button {
  margin-bottom: 44px;
}

/* input type number hide arrow */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
.mai-form-group-container {
  padding: 0 15px;
}

.app-switcher-container input:checked + .switch .slider {
  /* background-image: linear-gradient(225deg, #2edf9a 0%, #23b1e3 100%); */
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  background-image: var(--primary_gradient);
}

a {
  color: black;
}

.d-none {
  display: none !important;
}

/* Temp */
.sidebar-profile-icons a {
  display: flex;
}

.main-content {
  /* margin-left: 178px; */
  /* margin-right: 82px; */
  width: 100%;
  transition: all 0.2s ease-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* margin-top: 1rem; */
}

.dsp-auth-container .sidebar-open ~ .main-content {
  margin-left: 435px;
}

.warning-color {
  color: var(--warning_color) !important;
}

/* .mai-form-group {
  padding-bottom: 0;
} */

.mai-custom-control.mai-custom-inline-switcher
  .mai-custom-control-input:checked
  ~ .mai-custom-control-label {
  background: var(--primary_gradient);
}

textarea {
  resize: none;
}

.int-info-text {
  color: rgba(41, 47, 65, 0.5);
  font-size: 0.875rem;
  letter-spacing: 0.2px;
  line-height: 1rem;
  margin-bottom: 0.5rem;
}

.auth-form-wrapper-container {
  padding-top: 32px;
  background-color: #ffffff;
}

.main-color {
  color: var(--primary_color);
}

.link-hover {
  color: var(--primary_color);
}

.link-hover:hover {
  text-decoration: underline;
}

.react-datepicker__aria-live {
  display: none;
}