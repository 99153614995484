.invoice-details-wrap {
  min-height: 600px;
  background-color: #ffffff;

  .invoice-details-header {
    display: flex;
    min-height: 42px;
    align-items: center;
    padding: 12px 16px;
    border-bottom: solid 1px #0000003b;

    .navigate {
      display: flex;
      align-items: center;
      color: #3a77eb;
      width: 60%;
      font-weight: 600;
      cursor: pointer;
    }

    .invoice-details-header-title {
      width: 60%;
      font-size: 18px;
      font-weight: 600;
    }
    .invoice-details-header-actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 40%;

      .btn {
        width: fit-content;
        height: 35px;
        border: 1px solid rgba(0, 0, 0, 0.231372549);
        padding: 6px 12px;
        border-radius: 4px;
        line-height: 20px;
        cursor: pointer;

        &.inactive {
          color: #8f929b;
        }
        &.active {
          color: #292F41;
        }
      }

      .navigate,.download, .send {
        font-size: 16px;
        font-weight: 400;
        cursor: pointer;
        min-width: 42px;
      }
      .navigate, .send {
        color: #3a77eb;
      }

      .download {
        line-height: 12px;
        margin-left: 40px;
      }

      .download-wrap {
        position: relative;
        min-width: 82px;
        min-height: 24px;

        .download-loader {
          @keyframes spin {
            0% {
              transform: rotate(0);
            }
            100% {
              transform: rotate(360deg);
            }
          }
          border-radius: 50%;
          border: 3px solid transparent;
          border-top-color: #326afc6e;
          border-right-color: #326afc6e;
          animation: spin 1s linear infinite;
          width: 15px;
          height: 15px;
          position: absolute;
          right: 23px;
          top: 6px;
        }
      }
    }
  }

  .invoice-details-body {
    padding: 16px;

    .block-header {
      border-bottom: solid 1px #e3e4e5;
      margin-bottom: 16px;
      padding-bottom: 4px;

      p {
        font-size: 18px;
        font-weight: 600;
        margin-right: 42px;

      }
    }

    .general {
      .block-header {
        border-bottom: solid 1px #e3e4e5;
        margin-bottom: 16px;
        padding-bottom: 4px;
        display: flex;
        align-items: center;

        p {
          font-size: 18px;
          font-weight: 400;

          &.title-label{
            font-weight: 700;
            font-size: 17px;
            margin-right: 16px;
          }
        }
      }
    }

    .block-body {
      display: flex;
      margin-bottom: 36px;

      .left {
        display: flex;
        flex-direction: column;
        margin-right: 2%;

        .general-block-row {
          display: flex;
          border-bottom: solid 1px #e9ecef;

          .general-block-item {
            display: flex;
            padding: 4px 8px;
            margin-right: 12px;

            .item-label {
              margin-right: 10px;
              font-weight: 700;
              font-size: 14px;
            }
            .item-text {
              font-weight: 400;
              font-size: 14px;
            }
          }
        }
      }

      .core-table-wrap {
        width: 100%;

        .table-container {
          margin-top: 0;
          min-height: 30px;
        }
      }

      .core-table-wrap {
        &.no-table-data {
          .table-container {
            table {
              min-height: 330px !important;
            }
          }
        }
      }

    }
  }
}

