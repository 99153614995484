.campaign-type {
  height: 100%;
  display: flex;
  flex-direction: column;

  .campaign-type-header {
    display: flex;
    width: 100%;
    flex-direction: row;
    padding: 16px 20px 0 48px;
    justify-content: space-between;
    margin-bottom: 42px;

    .campaign-type-header-title {
      font-size: 24px;
      font-weight: 600;
      line-height: 1.17rem;
      letter-spacing: -0.2px;
      color: #292f41;
      padding: 10px 0;

    }
    .campaign-type-header-close-icon {
      padding: 0;
      width: 48px;
      height: 48px;
      cursor: pointer;
    }
  }

  .campaign-type-body {
    display: flex;
    height: 100%;
    width: 100%;
    // border-right: 1px solid #dfe7f0;
    justify-content: space-between;
    flex-direction: row;
    padding: 32px;

    .campaign-type-body-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      height: 100%;
      max-width: 220px;

      .campaign-type-body-item-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 190px;
        height: 160px;
        border: 1px solid rgba(0, 0, 0, 0.12);
        padding-top: 45px;
        border-radius: 4px;
      }
      .campaign-type-body-item-description {
        width: 190px;
        text-align: center;
        padding: 40px 16px;
        font-size: 12px;
        line-height: 1.33rem;
        color: rgba(41, 47, 65, 0.6);
      }
    }
  }
}