#modal-root > div {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.app-modal {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 9vh;
  left: 50%;
  /* width: 70%; */
  min-width: 454px;
  /* height: 524px; */
  transform: translateX(-50%);
  background: white;
  overflow-y: auto;
  border: 200px;
  border-radius: 15px;
  .mai-table-container {
    margin-top: 0;
    min-height: 420px;
  }
}

.app-modal-header {
  font-size: 24px;
  border-bottom: 1px solid gray;
  padding: 20px;
}

.app-modal-content {
  flex: 1;
}

.overlay {
  z-index: 9999;
}

.modal-header-container {
  width: 100%;
  min-height: 60px;
  border-radius: 12px 12px 0 0;
  box-shadow: inset 0 -1px 0 0 #f4f6fa;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-left: 16px;
  // text-transform: uppercase;
}

.modal-footer-container {
  width: 100%;
  /* height: 60px; */
  border-radius: 0 0 12px 12px;
  box-shadow: inset -1px 1px 0 0 #f4f6fa !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-left: 16px;
  text-transform: uppercase;
  z-index: 99;
}

.modal-title {
  min-height: 20px;
  color: #292f41;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0.5px;
  line-height: 20px;
  width: 100%;
  padding: 0 1.5rem 0 0.5rem;
}

@media screen and (max-width: 450px) {
  .app-modal {
    margin-top: 4rem;
    min-width: auto;
    .app-modal-content {
      .payment-modal {
        min-width: auto;
        .refill-modal {
          min-width: auto;
          .refill-modal-header {
            min-height: 80px;
            span {
              font-size: 2.2rem;
            }
          }
          .input-wrapper {
            padding: 0.1rem 0.5rem;
            width: 100%;
            .input-description {
              font-size: 0.7rem;
            }
            label {
              font-size: 0.7rem;
            }
            .mai-select-selection-selected-value {
              font-size: 1rem;
            }
          }
          .refill-modal-current-balance {
            .refill-modal-current-balance-value {
              font-size: 3rem;
            }
          }
        }
      }
    }
  }
  .app-modal .app-modal-content {
    overflow: hidden;
  }
  .app-modal .app-modal-content .html-banner-modal-container {
    margin: 0 20px;
    height: 220px;
  }
}

@media screen and (max-width: 414px) and (min-height: 736px) {
  .app-modal .app-modal-content .html-banner-modal-container {
    margin: 0 20px;
    height: 396px;
  }
}

@media screen and (max-width: 375px) and (min-height: 640px) {
  .app-modal .app-modal-content {
    width: 320px;
  }
  .app-modal .app-modal-content .html-banner-modal-container {
    margin: 0 20px;
    height: 396px;
  }
}

@media screen and (max-width: 320px) {
  .app-modal .app-modal-content {
    width: 300px;
  }
}
