.admin-accounts-wrap {

  display: flex;
  flex-direction: column;

  .accounts-container-graph-download-excel {
    padding-top: 1rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    color: var(--primary_color);
    font-weight: 600;
    margin-bottom: 0.8rem;

    span {
      display: inline-block;
      margin-right: 0.5rem;
    }

    div {
      cursor: pointer;
    }
  }

  .mai-filters {
    .input-row {
      margin: 1rem 0;
      width: 100%;

      &.admin-account-status {
        margin-left: 2rem;
      }

      .input-wrapper {
        padding: 0;
        width: 100%;

        .input-field {
          &:hover {
            fieldset {
              border-color: #3a77eb;
            }
          }

          input {
            padding: 8px 14px;
            font-size: 16px;
            line-height: 21px;
            color: #292f41;
            background: white;
          }
        }
      }
    }
  }
}
