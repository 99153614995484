.auth-title-wrapper-container {
  width: 100%;
  background-color: #ffffff;
  height: 88px;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
  position: fixed;
  z-index: 10;

  .auth-title {
    overflow: hidden;
    padding-left: 88px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    &.user {
      padding-left: 174px;
      .auth-title-meta {
        margin-left: 0;
      }
    }
    .auth-title-admin {
      cursor: pointer;
      padding: 1rem;
      padding-right: 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        left: 100%;
        top: 4px;
        width: 100%;
        height: 100%;
        background: url("../../../../assets/images/admin_arrow_right.svg");
        background-repeat: no-repeat;
        pointer-events: none;
      }
      .auth-title-admin-text {
        padding-right: 2.5rem;
      }

      .auth-title-admin-account-image {
        margin-right: 0.75rem;
        max-width: 64px;
        max-height: 64px;
        min-width: 64px;
        min-height: 64px;
        box-sizing: border-box;
        border: 4px solid #dfe7f0;
        border-radius: 50%;
        box-shadow: 0 16px 12px -12px rgba(58, 119, 235, 0.2);

        img {
          border-radius: 50%;
          width: 56px;
          height: 56px;
        }
      }
      .auth-title-admin-account {
        font-weight: bold;
        font-size: 0.75rem;
        color: rgba(41, 47, 65, 0.5);
        letter-spacing: 0.8px;
        text-align: left;
        line-height: 0.75rem;
      }
      .auth-title-admin-account-name {
        margin-top: 0.5rem;
        font-weight: 600;
        font-size: 1.5rem;
        color: #292f41;
        letter-spacing: -0.2px;
        text-align: left;
        line-height: 1.75rem;
        text-transform: capitalize;
        overflow: hidden;
        max-height: 30px;
      }
    }
    .auth-title-meta {
      display: flex;
      align-items: center;
      flex: 1;
      margin-left: 1.5rem;
      width: 50%;
      .int-icon {
        margin-right: 0.75rem;
      }
    }
    .auth-title-logout {
      padding-right: 5.375rem;
    }
    .auth-title-billing {
      display: flex;
      align-items: center;

      .auth-title-billing-container {
        padding: 1.25rem 1.5rem;
        a {
          display: contents;
        }
        background-color: #f4f6fa;
        display: flex;
        height: 88px;
        align-items: center;
      }
      .auth-title-billing-image {
        cursor: pointer;
        margin-left: 3rem;
        border-radius: 50%;
        height: 48px;
        width: 48px;
        background: var(--primary_gradient);
        transition: 0.3s ease-in-out;

        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }

        &:hover:not(.disabled) {
          opacity: 0.7;
          transition: 0.3s ease-in-out;
        }
      }
      .balance-wrap {
        width: 200px;
        height: 56px;
        display: flex;
        align-items: center;
        background-color: #f4f6fa;
        border-radius: 12px;
        margin-right: 1.5rem;
        padding: 8px;

        .balance {
          width: 154px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .balance-label {
            color: #292f4180;
            font-size: 12px;
            line-height: 12px;
            letter-spacing: 0.8px;
            width: 100%;
            text-align: end;
            font-weight: 600;
          }

          .balance-text {
            color: #2e79f7;
            font-size: 18px;
            font-weight: 600;
            width: 100%;
            height: 24px;
            line-height: 24px;
            text-align: end;
            text-transform: lowercase;
            position: relative;
          }
        }
        .balance-icon {
          margin-top: 11px;
          width: 46px;
          height: 46px;
        }
      }
    }
    .auth-title-text {
      word-break: break-word;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      overflow: hidden;
      font-size: 1.25rem;
      color: rgba(41, 47, 65, 0.5);
      text-align: left;
      letter-spacing: 0.5px;
      line-height: 1.25rem;
      font-weight: bold;
      width: 50%;
      .auth-title-breadcrumbs-arrow {
        margin: 0 1rem;
      }

      a {
        color: rgba(41, 47, 65, 0.5);
      }
      &.user {
        font-size: 2rem;
        line-height: 2.5rem;
        letter-spacing: 1.2px;
        font-weight: 300;
      }
    }

    .mai-icon {
      margin-right: 12px;
    }

    @media screen and (max-width: 450px) {
      padding-left: 100px;
      margin-right: 10px;
    }
    .auth-title-funds-title {
      color: rgba(41, 47, 65, 0.5);
      font-size: 0.875rem;
      letter-spacing: 0.8px;
      line-height: 1rem;
    }
    .auth-title-funds {
      font-weight: bold;
      color: var(--primary_color);
      font-size: 1.5rem;
      letter-spacing: -0.2px;
      text-align: left;
      line-height: 1.75rem;
      &.no-money {
        color: var(--gauge_color);
      }
      &.low-balance {
        color: var(--warning_color);
      }
    }
  }
  .auth-title-admin-arrow {
    .dropdown-arrow {
      transition: 0.3s ease-in-out;
      &.open {
        transition: 0.3s ease-in-out;
        transform: rotateZ(225deg);
      }
    }
  }
}

.auth-children {
  padding: 24px;
  //padding: 24px 86px;
  margin-left: 88px;
  background-color: #f4f6fa;
  margin-top: 88px;

  @media screen and (max-width: 450px) {
    padding: 0 10px;
    margin: 5rem 0 0 0;
  }
}
@media screen and (max-width: 450px) {
  .auth-title-admin {
    padding: 0 0 0 1rem !important;
    &::after {
      content: none !important;
    }

    .auth-title-admin-arrow {
      margin-left: 0.4rem;
    }
    .auth-title-admin-text {
      padding: 0 !important;
      .auth-title-admin-account-name {
        font-size: 1.3rem !important;
        margin: 0 !important;
      }
      .auth-title-admin-account {
        display: none;
      }
    }
  }
  .auth-title-wrapper-container {
    z-index: 10000;
    height: 4rem;
    .auth-title-meta {
      display: none !important;
    }
    .auth-title {
      padding: 0;
      .auth-title-logout {
        padding: 0;
      }
      .auth-title-billing {
        display: flex;
        align-items: center;
        .auth-title-logout {
          margin-right: 4rem;
          .mai-button {
            width: 4rem;
            border-radius: 1rem;
            height: 2.2rem;
            span {
              font-size: 0.56rem;
            }
          }
        }
        .auth-title-billing-container {
          padding: 0;
          height: auto;
          background-color: white;
          .auth-title-billing-image,
          .auth-title-funds,
          .auth-title-funds-title {
            display: none;
          }
        }
        .auth-title-billing-image {
          width: 40px;
          height: 40px;
          margin-left: 1rem;
        }
      }
      .auth-title-funds {
        font-size: 1.2rem;
        white-space: nowrap;
      }
    }

    .title-billing-container {
      padding: 1rem;
    }
  }
}
.auth-header {
  margin-left: 88px;
  margin-top: 88px;
}

.auth-header ~ .auth-children {
  margin-top: 154px;
}
.menu-icon {
  cursor: pointer;
  display: none;
  // position: relative;
}
@media only screen and (max-width: 450px) {
  .auth-header {
    margin-left: 0;
    margin-top: 64px;
  }
  .auth-header ~ .auth-children {
    margin-top: 0;
  }
  .menu-icon {
    display: block;
  }
  .mai-button {
    width: 4rem;
    border-radius: 1rem;
    height: 2.2rem;
    font-size: .56rem !important;
  }
  .mai-content-wrapper {
    .footer {
      flex-direction: initial;
    }
  }
}
