.loader-wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
  height: 75px;
}

.loading-image {
  height: 100%;
  width: 100%;
}

.loading-text {
  height: 16px;
  width: 164px;
  color: #292f41;
  font-size: 14px;
  letter-spacing: 0.8px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
}

@keyframes aniVertical {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.loader-icon {
  position: relative;
  width: 40px;
  height: 40px;
}
.loader-icon div {
  width: 40px;
  height: 40px;
  position: absolute;
  background: var(--primary_gradient);
  box-sizing: border-box;
  animation: aniVertical 1.5s ease;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}
.loader-icon div:nth-child(1) {
  clip-path: polygon(50% 0, 50% 25%, 25% 25%, 25% 50%, 0 50%, 0 0);
  top: -10%;
  left: -10%;
  animation-delay: 0s;
  opacity: 0.2;
}
.loader-icon div:nth-child(2) {
  clip-path: polygon(100% 0, 100% 50%, 75% 50%, 75% 25%, 50% 25%, 50% 0);
  top: -10%;
  right: -10%;
  animation-delay: 0.2s;
  opacity: 0;
}
.loader-icon div:nth-child(3) {
  clip-path: polygon(75% 75%, 75% 50%, 100% 50%, 100% 100%, 50% 100%, 50% 75%);
  top: 10%;
  right: -10%;
  opacity: 0;
  animation-delay: 0.6s;
}
.loader-icon div:nth-child(4) {
  clip-path: polygon(25% 50%, 25% 75%, 50% 75%, 50% 100%, 0 100%, 0 50%);
  top: 10%;
  left: -10%;
  opacity: 0;
  animation-delay: 1s;
}