.mai-content-wrapper {
  background: #ffffff;
  box-shadow: 0 12px 8px -10px rgba(0, 0, 0, 0.12);
  width: 100%;
  padding: 15px 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  border: 1px solid #dfe7f2;
  padding-top: 0;

  .header {
    border-bottom: 1px solid #f4f6fa;
    padding: 12px 15px;
    margin: 8px 0;
    font-size: 1.5rem;
    font-weight: 200;
    letter-spacing: -0.2px;
    line-height: 1.75rem;
  }

  .body {

    .mai-form-group-container {
      display: flex;
      margin: 1rem 0;
      padding: 0 0.5rem;
      width: 100%;

      .input-row {
        width: 100%;
      }
    }
  }

}
