.report-list {
  min-height: 450px;


  .MuiSvgIcon-root{
    &.MuiTableSortLabel-icon {
      opacity: 0.4;
    }
  }
  .table-skeleton {
    td {
      padding: 8px;
    }
  }
  .report-list-filter {
    height: 76px;
    padding: 21px 24px 19px 24px;
    background-color: #FFFFFF;

    & > svg {
      margin-top: 5px;
    }

    & > div {
      font-size: 14px;
      line-height: 36px;
      margin-right: 20px;
      height: 38px;
      font-weight: 700;

      & > div {
        font-weight: 700;
      }
      & > div:focus {
        background-color: transparent !important;
      }
    }
    .report-list-filter-dimensions-label {
      float: right;
      text-align: center;
      line-height: 36px;
      font-size: 14px;
      margin: 0 8px;
    }

    .report-list-filter-search-input {
      margin-left: 16px;
      & input {
        font-size: 18px;
      }
    }
    .report-list-filter-csv {
      font-size: 14px;
      font-weight: 600;
      line-height: 36px;
      color: #326AFC;
      cursor: pointer;
      text-align: center;
      border-left: 1px solid #E6E9ED;
      float: right;
      padding-left: 30px;
      padding-right: 16px;
      margin: 0;
    }
  }
  .report-list-head {
    background-color: #F4F6FA;

    .report-list-head-cell {
      text-transform: uppercase;
      font-size: 12px;
      text-align: start;
      padding: 6px 8px;
      color: #292F41;
      font-weight: 600;

      &:first-child {
        span {
          min-width: 150px;
        }
        .MuiSvgIcon-root{
          &.MuiTableSortLabel-icon {
            opacity: 0;
          }
        }
        min-width: 150px;
      }
    }
  }
}
.top-radius {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}
