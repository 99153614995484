.admin-account {
  background-color: #ffffff;
  padding: 0.375rem 1rem;
  // min-height: 75px;
  display: flex;
  justify-content: center;
  position: relative;
  cursor: pointer;
  &::after {
    bottom: 0;
    // left: 20%;
    position: absolute;
    content: "";
    height: 1px;
    background-color: #dfe7f0;
    display: block;
    border-bottom: 1px solid #dfe7f0;
    width: calc(100% - 2rem - 48px);
    left: 64px;
  }

  display: flex;
  align-items: center;
  &.is-admin {
    margin-bottom: 1rem;
    &::after {
      content: none;
    }
  }
  .admin-account-image {
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
    margin-right: 0.75rem;
    img {
      border-radius: 50%;
      width: 48px;
      height: 48px;
      border: 4px solid #dfe7f0;
      object-fit: cover;
    }

  }
  .admin-account-text-name {
    font-weight: 600;
    font-size: 1rem;
    color: #292f41;
    letter-spacing: 0;
    text-align: left;
    line-height: 1.2rem;
    margin-bottom: 0.2rem;
  }
  .admin-account-text-date {
    font-size: 0.75rem;
    color: rgba(41, 47, 65, 0.5);
    letter-spacing: 0.8px;
    text-align: left;
    line-height: 0.75rem;
    text-transform: uppercase;
  }
  .admin-account-icon {
    margin-left: 2rem;
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
}
