.create-audience-page {
  padding: 0 !important;
}

.create-audience-wrap {
  width: 100%;
  display: flex;
  background-color: #FFFFFF;
  padding: 24px 40px 100px 0;

  .audience-container {
    width: 80%;
    padding: 0 60px;

    .audience-name-input-field {
      display: flex;
      width: 690px;
      flex-direction: column;
      gap: 8px;
      position: relative;
      margin-top: 20px;

      .audience-name-label {
        color: #292f41;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: .8px;
      }
      .audience-name-input {
        background-color: #FFFFFF;
        border-radius: 4px;

        &>div {
          border-radius: 4px;
        }

        & fieldset {
          border-color: #E3E4E5;
        }

        &.error {
          & fieldset {
            border-color: red;
          }
        }

        input {
          padding: 8.5px 14px;
        }
      }
    }

    .audience-container-title {
      font-size: 24px;
      line-height: 30px;
      border-bottom: 1px solid #E3E4E5;
      padding-bottom: 20px;
    }

    .audience-container-body {
      .audience-container-body-include {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
      }
      .audience-container-body-exclude {
        padding-top: 20px;
        border-top: 1px solid #E3E4E5;
        display: flex;
        flex-direction: column;
      }

      .action-title {
        color: #7B7D84;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 10px;
      }

      .btn-container {
        button {
          border-radius: 24px;
          margin: 20px 8px;
          background: #F4F6FA;
          color: #326AFC;
          box-shadow: none;
          font-size: 14px;
          font-weight: 600;
        }
      }

      .condition-text {
        float: left;
        margin: 15px 0;
        text-transform: uppercase;
        color: #46C968;
        font-size: 14px;
        line-height: 16px;
        border-right: 1px solid #7B7D84;
        padding-right: 8px;
      }
      .condition-description {
        float: left;
        margin: 15px 0;
        color: #7B7D84;
        font-size: 14px;
        line-height: 16px;
        padding-left: 8px;
      }
    }

  }
  .info-container {
    width: 20%;

    .info {
      display: flex;
      flex-direction: column;
      padding: 24px 20px;
      background: #F4F6FA;
      border-radius: 16px;

      .info-header {
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        .circle {
          width: 12px;
          height: 12px;
          margin-right: 5px;
          border-radius: 50%;
          background: #FFBF43;
        }
        .info-title {
          font-size: 20px;
          font-weight: 400;
          line-height: 25px;
        }
      }
      .info-description {
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        margin-bottom: 19px;
      }
      .info-footer {
        display: flex;
        align-items: center;
        cursor: pointer;

        .icon {
          width: 16px;
          height: auto;
          margin-right: 7px;
        }
        .info-read-more {
          font-size: 13px;
        }
      }
    }
  }
}
.audience-page-footer {
  position: fixed;
  bottom: 0;
  right: 0;
  width: calc(100% - 90px);
  background-color: #fff;

  .audience-footer-container {
    padding: 20px 32px;
    display: flex;
    justify-content: flex-end;

    button {
      padding: 8px 12px;
      border-radius: 24px;
      height: 32px;
      width: 140px;

      &.cancel {
        color: #292f41;
        margin-right: 8px;
      }
    }
  }
}
