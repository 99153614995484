.text-container {
  &:hover {
    .text-tooltip {
      display: block;
      transition: 0.5s ease;
    }
  }
  .text-tooltip {
    display: none;
    position: absolute;
    bottom: 90%;
    color: black;
    border: 1px solid rgba(0, 0, 0, 0.399);
    background: white;
    padding: 2px 7px;
    font-size: 1rem;
    text-transform: initial;
    transition: 0.5s ease;
  }
}
