.admin-drawer {
  // height: calc(100vh - 88px);
  display: flex;
  .admin-drawer-content {
    padding: 1.5rem;
    height: 100vh;
    background-color: #f4f6fa;
    width: 750px;
  }

  .admin-navigation.dsp-admin-sidebar-2 {
    left: 838px;

    .dsp-logo-container {
      justify-content: center;

      p {
        font-size: 15px;
        color: #292f41c7;
        letter-spacing: 0.5px;
        line-height: 1.25rem;
        font-weight: 700;
      }
    }
    a {
      display: flex;
      align-items: center;
    }
  }
}

.admin-default-view {
  .admin-search-list {
    height: 74vh;
  }
  li {
    max-width: 340px;
  }
}
.admin-search-view-content {
  .admin-search-list {
    height: 70vh;
  }
}

.admin-search-list {
  overflow: hidden;
  overflow-y: auto;
  li {
    &:hover {
      background: #f2f8fc;
      transition: 0.3s;
    }
    // max-width: 350px;
    &:last-of-type {
      &::after {
        content: none;
      }
    }
  }
}
.admin-content {
  .admin-drawer-links {
    transition: var(--transition);
    position: absolute;
    height: 100%;
    max-width: 14rem;
    left: 0.1rem;
  }
}

.admin-content-inner {
  position: relative;
}

.admin-drawer-links {
  padding: 1.5rem;
  margin-right: 1rem;
  background-color: #dfe7f0;
  .admin-drawer-links-title {
    font-size: 0.75rem;
    font-weight: bold;
    letter-spacing: 0.8px;
    line-height: 0.75rem;
    text-transform: uppercase;
    padding: 1.2rem 0.5rem;
  }
  .admin-drawer-links-list {
    a {
      color: var(--primary_color);
    }
    li {
      padding: 1.2rem 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      font-size: 1rem;
      color: var(--primary_color);
      letter-spacing: 0;
      line-height: 1.25rem;
      font-weight: 600;
      border-top: 1px solid #bfd0e3;
      &.disabled {
        // opacity: 0.3;
        color: rgba(20, 20, 20, 0.5);
        cursor: not-allowed;
      }
      img {
        margin-left: 1rem;
        margin-top: 0.1rem;
        width: 12px;
        height: 12px;
      }
    }
  }
}

.admin-drawer-search-input{
  width: 100%;
  background-color: #FFFFFF;

  input {
    padding: 9px;
  }
}

@media only screen and (max-width: 450px) {
  .admin-drawer {
    .loader-wrapper {
      height: 100%;
      width: 100%;
    }
    .admin-search-list {
      height: 48vh !important;
      .admin-account {
        .admin-account-text {
          p {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 8.75rem;
          }
        }
      }
      .admin-campaign {
        .admin-campaign-content {
          .admin-campaign-name {
            p {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              width: 8.75rem;
            }
          }
        }
      }
    }
    .admin-drawer-links {
      width: 420px;
      padding: 0.3rem 1.5rem 0 1.5rem;
      .admin-drawer-links-title {
        padding: 0 0 0.5rem 0;
        margin-top: 0.5rem;
        font-size: 0.875rem;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          border: none;
          padding: 0.5rem 0;
          width: 8.75rem;
        }
        img {
          display: none;
        }
      }
    }
    flex-direction: column-reverse;
    .admin-drawer-content {
      width: 100vw;
      padding: 0.6rem 1.5rem;
      .mai-form-group {
        max-width: none;
      }
    }
  }
}
