.color-picker-field-container {
  height: 100%;
  position: relative;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
  "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  "Helvetica Neue", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .color-picker-field-label {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 0.8rem;
    display: inline-block;
  }
  .color-picker-chooser-container {
    padding: 0.5rem;
    border: 1px solid #dfe7f0;
    border-radius: 6px;
    position: absolute;
    top: -96px;
    left: 50px;
    width: 100%;
    height: 100%;
    width: 328px;
    height: 320px;
    background-color: #ffffff;
    z-index: 9;
    .color-picker-chooser-title {
      color: #999999;
      text-transform: uppercase;
    }
    &.right {
      right: 50px;
      left: inherit;
    }
    &::after,
    &::before {
      right: 100%;
      top: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    &::after {
      border-color: rgba(136, 183, 213, 0);
      border-right-color: #ffffff;
      border-width: 14px;
      margin-top: -14px;
    }
    &::before {
      border-color: rgba(194, 225, 245, 0);
      border-right-color: #dfe7f0;
      border-width: 16px;
      margin-top: -16px;
    }
    &.right {
      &::after,
      &::before {
        left: 100%;
        right: inherit;
      }
      &::after {
        border-left-color: #ffffff;
        border-right-color: transparent;
      }
      &::before {
        border-left-color: #dfe7f0;
        border-right-color: transparent;
      }
    }
  }

  .color-circle {
    width: 40px;
    height: 40px;
    // background: red;
    border-radius: 50%;
    border: 2px solid #ffffff;
    box-shadow: 0 4px 8px 1px rgba(58, 119, 235, 0.2);
  }
  .color-rgb-picker {
    display: flex;
    font-weight: 600;
    & > div:not(:last-of-type) {
      margin-right: 0.5rem;
    }
  }
  .color-hex-picker {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
  }
  .color-presets {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .color-circle {
      margin-bottom: 0.5rem;
      margin-right: 0.5rem;
      &:nth-last-of-type(6n + 1) {
        margin-right: 0;
      }
    }
  }
  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
