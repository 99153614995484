.billing-screen {
  .mai-table-container {
    margin-top: 1rem;
    min-height: 300px;
  }
  .payment-methods-and-funds {
    .mai-content-wrapper {
      padding: 2rem;
      .available-funds {
        color: rgba(41, 47, 65, 0.5);
        font-size: 1.125rem;
        line-height: 1.5rem;
      }
      .funds {
        font-size: 4rem;
        color: var(--primary_color);
        letter-spacing: -1px;
        line-height: 4.5rem;
        font-weight: 200;
        &.no-funds {
          color: var(--gauge_color);
        }
        &.low-balance {
          color: var(--warning_color);
        }
      }
    }
    .available-funds-fill {
      flex: 1;
    }
    .aviable-funds-info {
      .mai-button {
        font-weight: 400;
      }
      margin-bottom: 2rem;
      .insufficient-funds {
        color: rgba(41, 47, 65, 0.5);
        max-width: 380px;
        font-size: 1.125rem;
        line-height: 1.5rem;
        margin-right: 4.25rem;
      }
    }
    .insufficient-funds-icon {
      // display: inline-block;
      margin-right: 1.5rem;
    }
  }
}

.payment-methods {
  padding-top: 1rem;
  margin-top: 0.5rem;
  border-top: 1px solid #f4f6fa;
}

.refill-history {
  .title {
    white-space: nowrap;
  }
  .mai-filters {
    padding: 0 1.5rem;
  }
  .mai-content-wrapper {
    padding: 0;
    padding-top: 1.5rem;
  }
  .mai-content-wrapper {
    .app-paginate {
      background-color: #f4f6fa;
    }
  }
}
@media only screen and (max-width: 450px) {
  .refill-history {
    .mai-filters {
      flex-direction: column;
    }
    .mai-filters-date {
      margin: 0.5rem 0;
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .mai-filters-date-title {
      display: none;
    }
  }
  .auth-title-admin-account-image {
    display: none;
  }
  .billing-screen {
    .payment-methods-and-funds {
      div[role="presentation"] {
        margin-top: 0 !important;
        flex-direction: column !important;
        text-align: center;
        .insufficient-funds,
        img {
          margin: 0;
          font-size: 0.9rem;
        }
      }
      .aviable-funds-info {
        flex-direction: column;
        .mai-button {
          width: 9.375rem !important;
        }
      }
      .mai-content-wrapper {
        .funds {
          font-size: 2rem;
        }
      }
    }
    .billing-screen-add-payment-modal-wrapper {
      .mai-app-modal {
        margin-top: 5rem;
      }
    }
  }
}
