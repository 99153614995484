.addCreditCardContainer {
    max-width: 280px;
    max-height: 168px;
    width: 280px;
    height: 168px;
    background-color: rgba(242, 248, 252, 0.5);
    border: 1px solid #3A77EB;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin-bottom: 1rem;
    border-radius: 4px;
    justify-content: center;
}

.plusIcon {
    background-repeat: no-repeat;
    background-size: contain;
    width: 40px;
    height: 32px;
}


.addCardText {
    height: 16px;
    width: 135px;
    color: #292f41;
    font-family: "Source Sans Pro", serif;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.8px;
    line-height: 16px;
    text-align: center;
    padding-top: 9px;
    text-transform: uppercase;
}

