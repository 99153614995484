.reset-password-wrap {
  padding: 32px 60px;
  background-color: rgb(255, 255, 255);
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 8px 0px;
  gap: 16px;
  display: flex;
  flex-direction: column;
}


.reset-password-title {
  margin-bottom: 1rem;
  text-align: center;
}
