.file-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem 0.5rem;
  align-items: center;
  border-bottom: 1px solid #dfe7f0;
  padding-bottom: 30px;

  .label-wrapper{
    width: 30%;
    padding: 0 15px;
  }

  .input-wrapper{
    width: 65%;
    //padding: 0 15px;
  }
}

.file-input-filed-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  //padding-bottom: 1rem;

  .file-input-filed-label {
    padding-top: 0.5rem;
    width: 100%;
  }
  .file-input-filed-input-container {
    display: flex;
    width: 100%;
    align-items: flex-start;
  }
  input[type="file"].file-input-filed {
    // color: red;
    opacity: 0;
    display: none;
    &::-webkit-file-upload-button {
      display: none;
    }
  }
  .info {
    //margin-bottom: 0.5rem;
    color: #999999;
  }
  .input-error-message {
    bottom: 0.2rem;
  }
}

