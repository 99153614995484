.border-color-E6E9ED {
  & fieldset {
    border-color: #E6E9ED !important;
  }
}
.radius-8 {
  border-radius: 8px !important;
  & > div {
    & > div {
      border-radius: 8px !important;
    }
  }
}
.int-select {
  svg {
    color: #326AFC;
  }
}