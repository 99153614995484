.card-wrapper {
  width: 280px;
  height: 168px;
  max-width: 280px;
  max-height: 168px;
  border-radius: 4px;
  // background: url("../../assets/images/card_background.png"),
  // var(--primary_color) center center;
  background: var(--primary_gradient);

  padding: 16px;
  margin-bottom: 1rem;
  margin-right: 0.75rem;
  // margin-right: 16px;
  // margin-right: 18px;
  // margin-top: 16px;
  // background: var(--primary_gradient);
  @media only screen and (max-width: 450px) {
    margin-right: 0;
  }
}

.card-title {
  height: 28px;
  width: 208px;
  color: rgba(255, 255, 255, 0.9);
  font-family: "Source Sans Pro";
  font-size: 21px;
  font-weight: 200;
  letter-spacing: -0.2px;
  line-height: 28px;
  white-space: nowrap;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-number {
  height: 20px;
  width: 208px;
  color: #ffffff;
  font-family: "Source Sans Pro";
  font-size: 20px;
  // font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.card-exp-date {
  font-size: 0.825rem;
  color: #ffffff;
}

.credit-card-type {
  background-color: #ffffff;
  margin-top: 16px;
  overflow: hidden;
  height: 48px;
  width: 80px;
  img {
    width: 80px;
    height: 48px;
  }
}

.image-buttons-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.default-text {
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.8px;
  line-height: 16px;
  text-align: right;
  cursor: pointer;
  margin-top: 1rem;
}

.remove-text {
  cursor: pointer;
  color: #ffffff;
  font-family: "Source Sans Pro";
  font-size: 14px;
  letter-spacing: 0.8px;
  line-height: 16px;
  text-align: right;
  // margin-top: 1rem;
  padding-top: 8px;
}
