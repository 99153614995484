.admin-drawer {
  // height: calc(100vh - 88px);
  display: flex;
  .admin-user-content {
    padding: 1.5rem;
    height: 100vh;
    background-color: #f4f6fa;
    width: 750px;
  }
}

.admin-user-search-container {
  max-height: 400px;
  height: fit-content;
  position: absolute;
  border: 1px solid #f4f6fa;
  overflow: hidden;
  overflow-y: auto;
  width: 326px;
  z-index: 1;
  background-color: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  &::-webkit-scrollbar {
    width: 5px;

  }


  &::-webkit-scrollbar-thumb {
    background: #D9D9D9;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #D9D9D9;
  }


  .admin-user-not-found {
    padding: 24px;
    text-align: center;
    color: #8f929b;
    font-weight: 400;
    background-color: #FFFFFF;
    border: 1px solid #f4f6fa;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  }

  .admin-search-list {
    height: 100%;

    .admin-account.is-admin {
      margin-bottom: 0;
    }

    li {
      max-width: 324px;
      min-width: 324px;

      &:hover {
        background: #f2f8fc;
        transition: 0.3s;
      }
    }
    &:last-of-type {
      &::after {
        content: none;
      }
    }
  }
}

.admin-user-search-input-wrap {
   position: relative;

  .close {
    position: absolute;
    right: 6px;
    top: 6px;
    font-weight: 400;
    cursor: pointer;

    svg {
      width: 16px;
    }
  }

  .admin-user-search-input {
    width: 100%;
    background-color: #FFFFFF;

    input {
      padding: 6px 24px 6px 12px;
    }
  }
}




.invoice-list-header {
  padding: 16px 0 16px 24px;
  display: flex;
  justify-content: space-between;

  > div {
    font-size: 14px;
    font-weight: 700;
  }

  .currency-wrap {
    p {
      font-size: 14px;
      margin-right: 5px;
      line-height: 36px;
      float: left;

      &.currency-icon {
        font-weight: bold;
      }
    }
  }

  .btn {
    width: fit-content;
    height: 35px;
    border: 1px solid rgba(0, 0, 0, 0.231372549);
    padding: 6px 12px;
    border-radius: 4px;
    line-height: 20px;
    cursor: pointer;

    &.inactive {
      color: #8f929b;
    }
    &.active {
      color: #292F41;
    }
  }

  .invoice-list-filter-date-label {
    float: left;
    text-align: center;
    line-height: 36px;
    font-size: 14px;
    margin: 0 8px 0 0;
  }
}

