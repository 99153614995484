.reset-password-form {
  width: 100%;
  margin: auto;
  position: relative;
}
.mai-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 32px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  border-radius: 20px;
  border: 0;
  color: #ffffff;
  padding: 10px 20px;
  transition: all 0.3s ease;
  letter-spacing: 0.8px;
  line-height: 16px;
  text-align: center;
  vertical-align: center;
  text-transform: uppercase;
  margin: 0.5rem 0.2rem;
  transition: all 0.2s ease-out;
  outline: none;
  background-color: #3a77eb;
}
