.my-account-customize-color-container {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding: 24px 42px 0 42px;
}

.input-row {
  &.customization-company-name {
    border-bottom: 1px solid #dfe7f0;
    padding-bottom: 30px;

    .input-wrapper {
      width: 66.5%;

      .input-field {
        &:hover {
          fieldset {
            border-color: #3a77eb;
          }
        }
      }
    }
  }

}

