.table-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 100px 10px;
  .table-not-found-logo {
    // height: 32.18px;
    width: 200px;
    // background-image: var(--main-logo);
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: contain;
    // opacity: 0.5;
  }
  .table-not-found-title {
    color: rgba(41, 47, 65);
    font-size: 28px;
    font-weight: 600;
    letter-spacing: -0.2px;
    line-height: 36px;
    text-align: center;
    margin-bottom: 1rem;
    margin-top: 1.2rem;
    white-space: pre-line;
    max-width: 700px;
  }
  .table-not-found-description {
    color: rgba(41, 47, 65, 0.5);
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 1.5rem;
    max-width: 500px;
    white-space: pre-line;
  }
  .mai-button {
    font-weight: bold;
  }
}
@media only screen and (max-width: 450px) {
  .table-not-found {
    a {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}
