.dashboard-stats {
  width: 100%;
  min-height: 156px;
  box-sizing: border-box;
  border: 1px solid #dfe7f0;
  border-radius: 0.25rem;
  background-color: #ffffff;
  box-shadow: 0 12px 8px -10px rgba(0, 0, 0, 0.12);
  padding: 1rem;
  text-align: left;
  transition: 0.3s;
  &:hover {
    background-color: rgba(205, 213, 222, 0.4);
    transition: 0.3s;
  }
  &.disabled {
    &:hover {
      background-color: #ffffff;
    }
  }

  &.active {
    background-color: rgba(205, 213, 222, 0.2);
  }
  .dashboard-stats-container {
    display: flex;
    justify-content: space-between;
  }
  .dashboard-stats-wrapper {
    display: flex;
    margin-bottom: 1rem;
    justify-content: space-between;
  }
  // margin-bottom: 0.5rem;

  .loader-wrapper {
    .loader-container {
      padding-top: 3.5rem;
    }
  }
  p {
    margin: 0;
    padding: 0;
  }

  .dashboard-stats-label {
    font-size: 0.75rem;
    color: rgba(41, 47, 65, 0.5);
    letter-spacing: 0.8px;
    line-height: 0.75rem;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-right: 4px;
    font-weight: 600;
  }
  .dashboard-stats-value {
    font-weight: 600;
    font-size: 1.25rem;
    color: #292f41;
    letter-spacing: 0.8px;
    text-align: left;
    line-height: 1.25rem;
    // margin-bottom: 2rem;
    white-space: nowrap;
    margin-right: 0.5rem;
  }

  .dashboard-stats-verus-label {
    font-size: 0.875rem;
    color: #292f41;
    letter-spacing: 0.2px;
    text-align: left;
    line-height: 1.25rem;
    margin-top: 0.5rem;
  }

  .loader-wrapper {
    height: auto;
  }
}
