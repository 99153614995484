.int-drawer {
  position: fixed;
  z-index: 1000;
  width: 0%;
  height: 100%;
  // -webkit-transition: height 0s ease 0.3s, width 0s ease 0.3s,
  //   -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  // transition: height 0s ease 0.3s, width 0s ease 0.3s,
  //   -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  // transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), height 0s ease 0.3s,
  //   width 0s ease 0.3s;
  // transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), height 0s ease 0.3s,
  //   width 0s ease 0.3s, -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.int-drawer > * {
  // -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
  //   -webkit-box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  // transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
  //   -webkit-box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  // transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
  //   box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  // transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
  //   box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
  //   -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
  //   -webkit-box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.int-drawer-content-wrapper {
  position: absolute;

  // width: 50%;
}
.int-drawer .int-drawer-content {
  width: 100%;
  height: 100%;
}
.int-drawer-left,
.int-drawer-right {
  top: 0;
  width: 0%;
  height: 100%;
}
.int-drawer-left .int-drawer-content-wrapper,
.int-drawer-right .int-drawer-content-wrapper {
  height: 100%;
}
.int-drawer-left.int-drawer-open,
.int-drawer-right.int-drawer-open {
  width: 100%;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
    -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.int-drawer-left.int-drawer-open.no-mask,
.int-drawer-right.int-drawer-open.no-mask {
  width: 0%;
}
.int-drawer-left.int-drawer-open .int-drawer-content-wrapper {
  -webkit-box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
}
.int-drawer-right {
  right: 0;
}
.int-drawer-right .int-drawer-content-wrapper {
  right: 0;
}
.int-drawer-right.int-drawer-open .int-drawer-content-wrapper {
  -webkit-box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
}
.int-drawer-right.int-drawer-open.no-mask {
  right: 1px;
  -webkit-transform: translateX(1px);
  -ms-transform: translateX(1px);
  transform: translateX(1px);
}
.int-drawer-top,
.int-drawer-bottom {
  left: 0;
  width: 100%;
  height: 0%;
}

.int-drawer-top .int-drawer-content-wrapper,
.int-drawer-bottom .int-drawer-content-wrapper {
  // width: 100%;
}
.int-drawer-top.int-drawer-open,
.int-drawer-bottom.int-drawer-open {
  height: 100%;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
    -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.int-drawer-top.int-drawer-open.no-mask,
.int-drawer-bottom.int-drawer-open.no-mask {
  height: 0%;
}

.int-drawer-top {
  top: 88px;
  left: 88px;
}
.int-drawer-top.int-drawer-open .int-drawer-content-wrapper {
  // -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  height: calc(100% - 88px);
}
.int-drawer-bottom {
  bottom: 0;
}
.int-drawer-bottom .int-drawer-content-wrapper {
  bottom: 0;
}
.int-drawer-bottom.int-drawer-open .int-drawer-content-wrapper {
  -webkit-box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
}
.int-drawer-bottom.int-drawer-open.no-mask {
  bottom: 1px;
  -webkit-transform: translateY(1px);
  -ms-transform: translateY(1px);
  transform: translateY(1px);
}
.int-drawer.int-drawer-open .int-drawer-mask {
  height: 100%;
  opacity: 1;
  -webkit-transition: none;
  transition: none;
  // -webkit-animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  // animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.int-drawer-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.int-drawer-content {
  position: relative;
  z-index: 1;
  // overflow: auto;
  // background-color: #fff;
  background-clip: padding-box;
  border: 0;
}
.int-drawer-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: block;
  width: 56px;
  height: 56px;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  text-rendering: auto;
}
.int-drawer-close:focus,
.int-drawer-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.int-drawer-header {
  position: relative;
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
}
.int-drawer-header-no-title {
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
}
.int-drawer-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
}
.int-drawer-wrapper-body {
  height: 100%;
  overflow: auto;
}
.int-drawer-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: rgba(0, 0, 0, 0.45);
  opacity: 0;
  filter: alpha(opacity=45);
  -webkit-transition: opacity 0.3s linear, height 0s ease 0.3s;
  transition: opacity 0.3s linear, height 0s ease 0.3s;
}
.int-drawer-open-content {
  -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
@-webkit-keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media only screen and (max-width: 450px) {
  .int-drawer-top {
    left: 0;
    margin-top: 5rem;
    top: 0;
    .admin-default-view {
      .admin-default-view-container {
        min-width: 100%;
      }
      .campaigns {
        display: none;
      }
    }
  }
}
