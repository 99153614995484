.core-table-wrap {

  .table-container {

    margin-top: 2.5rem;
    width: 100%;
    box-shadow: 0 12px 8px -10px rgba(58, 119, 235, .2);
    border-radius: 4px;
    background-color: #fff;
    min-height: 596px;
    overflow-y: hidden;

    table {

      position: relative;

      thead {
        height: 32px;

        tr {
          background-color: #dfe7f1;
          font-size: 0.8rem;
          height: inherit;
          width: 100%;
          font-weight: 400;

          th {
            text-align: left;
            color: rgba(41, 47, 65, .5);
            text-transform: uppercase;
            font-weight: 600;
            padding: 0.5rem 1.5rem;
            font-size: .8rem;
          }
        }
      }

      tbody {
        td {
          padding: 0.5rem 1.5rem;
        }
      }

      .table-not-found {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 100px 10px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }
}